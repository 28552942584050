import { submitSourceUrl, addBuriedPoint, proxyHost, getOssSecret, roleTypeMap, allocationStrategyMap, submitSourceAll } from '@/apis'
import { Dialog, Toast } from 'vant'
import { updateUniqueFileList } from '@/tools/file-upload'
import { getUrlParams, userAccessValid } from "../shared";
import { formatDate } from '@/tools/date'
import { getTokenToObj } from '@/tools/utils'
import { sentryReportError } from '../../../tools/sentry';

// 快驴任务类型
const KL_TASK_DATATYPE = 50

export default {
    data() {
        return {
            validateParams: false,
            urlParams: {},
            isCrowdSource: false,
            isSupper: false
        }
    },
    async created() {
        this.urlParams = getUrlParams(this.$route.query)
        document.title = this.urlParams.name || ''
        this.validateParams = this.verifyParams()
        const { allocationStrategy, roleType } = getTokenToObj() || {};
        this.isCrowdSource = +allocationStrategy === allocationStrategyMap.CROWD_SOURCE;
        this.isSupper = +roleType === roleTypeMap.SUPPER  
        if (this.validateParams) {
            await this.getUserAccessValid()
        }
    },
    methods: {
        // 校验参数
        verifyParams() {
            const { userTaskId, jobId, data } = this.urlParams
            return Boolean(userTaskId && jobId && data)
        },
        // h5鉴权
        async getUserAccessValid() {
            const { dataType } = this.urlParams
            if (dataType == KL_TASK_DATATYPE) {
                await this.getDetailApis()
            } else {
              let isValid = false
              // 超管且是众包任务，跳过校验，可直接查看任务详情
              if(this.isCrowdSource && this.isSupper){
                isValid = true;
              }else{
                isValid = await userAccessValid(this.urlParams);
              }
              this.isClose = isValid
              if (isValid) {
                  await this.getDetailApis()
              }
            }
            
        },
        // 获取详情接口
        async getDetailApis() {
            await this.getJobDetails()
            await this.getOssSecret()
        },
        // 获取阿里云签名
        async getOssSecret() {
            try {
                const res = await getOssSecret()
                if (res.status === '200' && res.data) {
                    this.uploadOptions = res.data
                    this.uploadTips = ''
                } else {
                    this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
                    Toast.fail({
                    duration: 4000, 
                    message: '获取签名失败，请稍后重试',
                    })
                }
            } catch (e) {
                this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
                Toast.fail({
                    duration: 4000,
                    message: e.message || '获取签名失败',
                });
                sentryReportError(e, {
                    scene: '签名获取失败',
                });
            }
        },
        async fetchTaskNew(submitInfo, imgs, taskType, invalidNum) {
            const {
                jobId = '',
                cityId = '',
                userTaskId = '',
                dataType = '',
                data = ''
            } = this.urlParams;
            const waitForSaveList = imgs.filter(item => !item.saveStatus);
            const clickButtonTime = Date.now();
            const taskJson = {
                jobId,
                cityId,
                userTaskId,
                dataType,
                companyId: this.companyId || '',
                data,
            }
            const {
                deviceList,
                picDurationList,
                picFormatList,
                picHeightList,
                picSizeList,
                picWidthList,
                uploadStartTimeList,
                uploadEndTimeList,
                picCreateList,
                chooseFileTimeList,
                uploadImageNum,
            } = submitInfo || {};
            const submitParams = (waitForSaveList || []).map((item,index) => {
                item.status = "uploading";
                item.message = "提交中";
                return {
                    resourceUrl: item.ossUrl,
                    taskInfo: JSON.stringify({
                        urlList: [item.ossUrl],
                        channel: 'LGTJ',
                        fileType: item.type === '图片' ? 'img' : 'video',
                        extra: [Object.assign(taskJson, {
                            device: deviceList[index],
                            version: '',
                            picSize: picSizeList[index],
                            picFormat: picFormatList[index],
                            picCreate: picCreateList[index],
                            picDuration: picDurationList[index],
                            picWidth: picWidthList[index],
                            picHeight: picHeightList[index],
                            picCommit: uploadStartTimeList[index],
                            picCommitFinish: uploadEndTimeList[index],
                            picSDK: 0,
                            picSDKFinish: 0,
                            compressFileUrl: '',
                            host: proxyHost,
                            deviceEnd: 'H5',
                            text: JSON.stringify({
                                chooseFileTime: chooseFileTimeList[index],
                                clickButtonTime,
                                handlerDataType: item.imageType || undefined,
                                submitTime: clickButtonTime,
                                fileSubmitTotalNum: item.entryLen || waitForSaveList.length,
                                imageSubmitTotalNum: uploadImageNum,
                            }),
                            handleDataType: 'A',
                            layoutType: 2
                        })],
                    }),
                    dataType,
                    taskType: this.taskType || '',
                }
            });
            try {
                const response = await submitSourceAll(submitParams);
                if(response && response.status === "200"){
                    waitForSaveList.forEach(item => {
                        item.status = "done";
                        item.file.saveStatusText = "已提交";
                        item.saveStatus = true;
                    });
                    this.buryAndReport(waitForSaveList);
                } else {
                    waitForSaveList.forEach(item => {
                        item.status = "done";
                    });
                }
            } catch (error) {
                waitForSaveList.forEach(item => {
                    item.status = "done";
                });
            }

            const submitSuccessList = waitForSaveList.filter(item => item.saveStatus);
            // C端活动 userTaskId_invalid
            const userTaskIdUnique = taskType === 'activity' ? `${userTaskId}_${invalidNum}` : userTaskId
            // sku采集类型不上传图片缓存
            const updateFileList = taskType === 'sku' ? submitSuccessList.filter(item => /video/.test(item.file.type)): submitSuccessList
            updateUniqueFileList(updateFileList, userTaskIdUnique)
            const failList = imgs.filter(item => !item.saveStatus)
            const dateTime = formatDate(clickButtonTime, 'yyyy-MM-dd HH:mm:ss')
            const cityName = this.cityName || ''
            const districtName = this.districtName || ''
            const splitArr = cityName && districtName && '-'
            const userLevel = this.userLevel || ''
            const userCode = this.userCode || ''
            const companyName = this.companyName || ''
            const searchKeyword = this.searchKeyword || ''
            const deliveryArea = this.deliveryArea || ''
            let title = ''
            let msg = ''
            if (['sku', 'skuSearch', 'skuApk'].includes(taskType)) {
                const fileType = taskType === 'skuApk' ? '文件' : '图片/视频'
                msg = this.needObscure
                    ? `提交成功 ${submitSuccessList.length} 个`
                    : `${dateTime} 提交成功 ${submitSuccessList.length} 个 ${
                    (cityName || districtName) && `${cityName}${splitArr}${districtName} 的`
                    }${fileType}，继续加油哦！`
            } else if (taskType === 'activity') {
                title = submitSuccessList.length ? '提交成功' : '提交失败'
                msg = `${dateTime}-${userCode}-${cityName}-${userLevel} ${this.confirmMessageStr}。`
            } else if (taskType === 'supplierLeads') {
              msg = `${dateTime}-${userCode}-${companyName}-${deliveryArea}-${searchKeyword} 提交成功 ${submitSuccessList.length} 张图片。`
            } else if (taskType === 'klResearch') {
              title = '提交成功'
              msg = `提交成功 ${submitSuccessList.length} 个`
            }
            let confirmButtonText = taskType === 'klResearch' ? '好的' : '继续上传'
            if(this.isCrowdSource && ['sku', 'skuApk'].includes(taskType)){
                confirmButtonText = '领取新任务';
            }
            let type = 'confirm'
            if (failList.length) {
                msg += `还有 ${failList.length} 提交失败，请重试~`
                confirmButtonText = '再次提交'
                type = 'alert'
            }
            const options = {
                title: title,
                message: msg,
                confirmButtonText,
                cancelButtonText: '完成关闭'
            }
            if (taskType === 'klResearch') {
              options.showCancelButton = false;
            }
            // 上传有失败的时候留在原页面，可再次提交
            try {
                await Dialog[type](options)
                if (!failList.length) {
                    if(this.isCrowdSource && ['sku', 'skuApk'].includes(taskType)){
                        this.$router.replace({
                            path: "/crowdSourceTaskList",
                            query: {
                                getTask: true
                            }
                        });
                    }else{
                        if (taskType === 'klResearch') {
                            this.updateStatus && await this.updateStatus()
                        }
                        this.$router.go(0)
                    }
                }
            } catch (e) {
                window.close()
                this.isClose = false
            }
        },
        async buryAndReport(waitForSaveList){
            const pointRes = waitForSaveList.map(async item => {
                const pointValue = JSON.stringify({
                    userTaskId: this.urlParams.userTaskId,
                    isCut: false,
                    normalFileName: item.ossUrl,
                    fileCreatedTime: item.lastModified,
                    startUploadTime: item.uploadStartTime,
                    endUploadTime: item.uploadEndTime,
                    uploadStatus: item.saveStatus ? 1 : 2,
                    source: 2,
                    path: process.env.CHANNEL || '',
                    handleDataType: item.imageType || undefined
                })
                return await addBuriedPoint({
                    pointKey: 'fileUploadPoint',
                    pointValue
                })
            });
            await Promise.all(pointRes);
        }
    }
}